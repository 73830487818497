
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { CategoryObject } from '@/store/modules/clients/CategoriesModule';

  export default defineComponent({
    name: 'category-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const route = useRoute();
      const selectedItem = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('categoryUpdating'), [
          translate('clients'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_CATEGORY,
        route.params.id
      );

      const category = ref<CategoryObject>(data.data);

      const titleAr = () => {
        return category.value.name.find((i) => {
          if (i.languageCode === 'ar') {
            return i.title;
          }
        }).title;
      };
      const titleEn = () => {
        return category.value.name.find((i) => {
          if (i.languageCode === 'en') {
            return i.title;
          }
        }).title;
      };
      const titleKu = () => {
        return category.value.name.find((i) => {
          if (i.languageCode === 'ku') {
            return i.title;
          }
        }).title;
      };

      const goBack = () => {
        router.back();
      };

      const categorySchema = Yup.object().shape({
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleKu: Yup.string()
          .required(() => translate('KU_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ku'),
      });
      const onSubmitCreate = async (values) => {
        values.name = [];
        values.name.push({ languageCode: 'en', title: values.titleEn });
        values.name.push({ languageCode: 'ar', title: values.titleAr });
        values.name.push({ languageCode: 'ku', title: values.titleKu });

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const payload = {
          name: values.name,
          id: route.params?.id,
        };
        await store.dispatch(Actions.UPDATE_CATEGORY, {
          data: payload,
          id: category.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_CATEGORY'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'categories-listing' });
          });
        }
      };

      return {
        titleAr,
        titleEn,
        titleKu,
        onSubmitCreate,
        selectedItem,
        submitButton,
        category,
        translate,
        categorySchema,
        goBack,
        can,
      };
    },
  });
